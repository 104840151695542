@media (min-width: 997px) {
  .slider {
    display: none;
  }
}

.slider__screen {
  --ratioMobile: 857 / 361;
  background-position: 0 0;
  background-repeat: no-repeat;
  min-height: calc(100vw * var(--ratioMobile));
  background-size: 100% 100%;
}

.slider__container {
  max-width: 218px;
  padding-top: 75%;
  margin-left: 10%;
}

.slider__title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 110%;
  color: #ffffff;
}

.slider__subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 40px;
}

.slider__link {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  padding: 9px 17px;
  background-color: #ffffff;
  border: 1px solid #fff;
  border-radius: 10px;
}

.swiper-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  margin-left: 5%;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: transparent;
  padding: 5px;
  border: 2px solid #FFFFFF;
  opacity: 1;
}

.swiper-pagination-bullet {
  background-color: #FFFFFF;
  opacity: 1;
}
