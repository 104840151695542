@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.preloader {
  background-color: #00bbaa;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
}

.preloader__img {
  width: 350px;
  height: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
