.aboutUs {
  padding-bottom: 60px;
  padding-top: 60px;
}

.aboutUs__title {
  font-family: "Playfair Display", serif;
  margin-bottom: 67px;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 120%;
  color: #00bbaa;
}

.aboutUs__content {
  font-size: 28px;
  line-height: 160%;
  color: #2c3e50;
  max-width: 1440px;
  margin-left: 100px;
}

@media (max-width: 1600px) {
  .aboutUs__content {
    max-width: 1240px;
  }
}

@media (max-width: 1440px) {
  .aboutUs__content {
    max-width: 1040px;
  }
}

@media (max-width: 1280px) {
  .aboutUs__content {
    max-width: 880px;
    font-size: 24px;
  }
}

@media (max-width: 1024px) {
  .aboutUs__content {
    max-width: 924px;
    font-size: 22px;
  }
}

@media (max-width: 650px) {
  .aboutUs__content {
    max-width: 450px;
  }
}

@media (max-width: 650px) {
  .aboutUs__content {
    font-size: 28px;
    margin-left: 0px;
    max-width: 450px;
  }
}

@media (max-width: 480px) {
  .aboutUs__content {
    max-width: 380px;
    font-size: 22px;
    padding-bottom: 75px;
  }
}

@media (max-width: 414px) {
  .aboutUs__content {
    font-size: 20px;
  }
}
