.main {
  background-color: #000;
}

@media (max-width: 996px) {
  .main {
    display: none;
  }
}

.main__container {
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 1600px) {
  .main__container {
    max-width: 1400px;
  }
}

@media (max-width: 1440px) {
  .main__container {
    max-width: 1240px;
  }
}

@media (max-width: 1280px) {
  .main__container {
    max-width: 1080px;
  }
}

@media (max-width: 1100px) {
  .main__container {
    max-width: 950px;
  }
}

@media (max-width: 1024px) {
  .main__container {
    max-width: 924px;
  }
}

.main__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 10%;
}

.main__item {
  width: 21%;
  margin-right: -25px;
}

.main__item_header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 55;
  width: 100%;
}

.main__item_content {
  position: absolute;
  bottom: 5%;
  left: 10%;
  z-index: 50;
  max-width: 340px;
}

.main__item_title {
  font-family: "Playfair Display", serif;
  font-size: 60px;
  line-height: 100%;
  color: #00bbaa;
  margin-bottom: 10px;
}

@media (max-width: 1440px) {
  .main__item_title {
    font-size: 48.1437px;
  }
}

@media (max-width: 1280px) {
  .main__item_title {
    font-size: 42px;
  }
}

@media (max-width: 1024px) {
  .main__item_title {
    font-size: 36px;
  }
}

.main__item_subtitle {
  font-size: 18px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 20px;
}

@media (max-width: 1440px) {
  .main__item_subtitle {
    font-size: 16px;
  }
}

@media (max-width: 1280px) {
  .main__item_subtitle {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .main__item_subtitle {
    font-size: 12px;
  }
}

.main__item_link {
  padding: 8px 12px;
  border: 2px solid #fff;
  border-radius: 8px;
  background-color: #ffffff;
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #000;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.main__item_link:hover {
  color: #00bbaa;
  text-decoration: underline;
}

.main-slider {
  position: relative;
}

.main-slider__header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

:root {
  --white: #3396cd;
  --black: #3396cd;
  --grey: #3396cd;
}

.header-toggle {
  background-color: rgba(0, 0, 0, 0.329);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  padding-bottom: 15px;
}

/* Logo */
.logo {
  display: inline-block;
  color: var(--white);
  margin-left: 20px;
  margin-top: 15px;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 35.8423px;
  line-height: 100%;
}

.logo-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.logo-box img {
  margin-right: 10px;
}

/* Nav menu */
.nav {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  overflow: hidden;
}

.menu {
  text-align: center;
}

.menu a {
  display: block;
  padding: 15px;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 110%;
  color: #3396cd;
}

.nav {
  max-height: 0;
  -webkit-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;
}

/* Menu Icon */
.hamb {
  cursor: pointer;
  float: right;
  padding: 40px 20px;
  margin-top: 10px;
}

.hamb-line {
  background: var(--white);
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
}

.hamb-line::before,
.hamb-line::after {
  background: var(--white);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamb-line::before {
  top: 5px;
}

.hamb-line::after {
  top: -5px;
}

.side-menu {
  display: none;
}

/* Toggle menu icon */
.side-menu:checked ~ nav {
  max-height: 275px;
  background-color: #fff;
}

.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}

.side-menu:checked ~ .hamb .hamb-line::before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 0;
}

.side-menu:checked ~ .hamb .hamb-line::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 0;
}

/* Responsiveness */
@media (min-width: 997px) {
  .header-toggle {
    display: none;
  }
}
