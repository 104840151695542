.container {
  max-width: 1700px;
  margin: 0 auto;
}

@media (max-width: 1600px) {
  .container {
    max-width: 1400px;
  }
}

@media (max-width: 1440px) {
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 1080px;
  }
}

@media (max-width: 1100px) {
  .container {
    max-width: 950px;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 924px;
  }
}

@media (max-width: 931px) {
  .container {
    max-width: 831px;
  }
}

@media (max-width: 839px) {
  .container {
    max-width: 739px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 668px;
  }
}

@media (max-width: 700px) {
  .container {
    max-width: 600px;
  }
}

@media (max-width: 650px) {
  .container {
    max-width: 450px;
  }
}

@media (max-width: 480px) {
  .container {
    max-width: 380px;
  }
}

@media (max-width: 414px) {
  .container {
    max-width: 314px;
  }
}
