.footer {
  background-color: #222;
}

.footer__container {
  max-width: 1700px;
  margin: 0 auto;
  padding-top: 50px;
}

@media (max-width: 1600px) {
  .footer__container {
    max-width: 1400px;
  }
}

@media (max-width: 1440px) {
  .footer__container {
    max-width: 1240px;
  }
}

@media (max-width: 1280px) {
  .footer__container {
    max-width: 1080px;
  }
}

@media (max-width: 1100px) {
  .footer__container {
    max-width: 950px;
  }
}

@media (max-width: 1024px) {
  .footer__container {
    max-width: 924px;
  }
}

@media (max-width: 931px) {
  .footer__container {
    max-width: 831px;
  }
}

@media (max-width: 839px) {
  .footer__container {
    max-width: 739px;
  }
}

@media (max-width: 768px) {
  .footer__container {
    max-width: 668px;
  }
}

@media (max-width: 700px) {
  .footer__container {
    max-width: 600px;
  }
}

@media (max-width: 650px) {
  .footer__container {
    max-width: 450px;
  }
}

@media (max-width: 480px) {
  .footer__container {
    max-width: 380px;
  }
}

@media (max-width: 414px) {
  .footer__container {
    max-width: 314px;
  }
}

.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 650px) {
  .footer__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.footer__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1100px) {
  .footer__logo {
    margin-right: 20px;
  }
}

@media (max-width: 839px) {
  .footer__logo {
    margin-right: 20px;
  }
}

.footer__logo_img {
  max-width: 200px;
}

.footer__kinds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer__kinds_item {
  margin-bottom: 17px;
}

.footer__kinds_item a {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  color: #00bbaa;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .footer__kinds_item a {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .footer__kinds_item a {
    font-size: 17px;
  }
}

.footer__kinds_item a:hover {
  text-decoration: underline;
}

.footer__kinds_item a:active {
  color: #f3bc65;
}

.footer__kinds_left {
  margin-top: 66px;
  margin-right: 56px;
}

@media (max-width: 650px) {
  .footer__kinds_left {
    margin-top: 35px;
  }
}

.footer__kinds_right {
  margin-top: 66px;
  margin-right: 68px;
}

@media (max-width: 650px) {
  .footer__kinds_right {
    margin-top: 35px;
  }
}

.footer__contacts_tel {
  margin-bottom: 6px;
}

.footer__contacts_tel a {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 130%;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 931px) {
  .footer__contacts_tel a {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .footer__contacts_tel a {
    font-size: 18px;
  }
}

@media (max-width: 650px) {
  .footer__contacts_tel a {
    font-size: 28px;
  }
}

.footer__contacts_tel a:hover {
  color: #00bbaa;
  text-decoration: underline;
}

.footer__contacts_tel a:active {
  color: #f3bc65;
}

.footer__contacts_email {
  margin-bottom: 35px;
}

.footer__contacts_email a {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 130%;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 931px) {
  .footer__contacts_email a {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .footer__contacts_email a {
    font-size: 18px;
  }
}

@media (max-width: 650px) {
  .footer__contacts_email a {
    font-size: 28px;
  }
}

.footer__contacts_email a:hover {
  color: #00bbaa;
  text-decoration: underline;
}

.footer__contacts_email a:active {
  color: #f3bc65;
}

.footer__contacts_address {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 130%;
  color: #fff;
}

@media (max-width: 768px) {
  .footer__contacts_address {
    font-size: 18px;
  }
}

.footer__middle {
  margin-top: 68px;
  margin-bottom: 45px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #fff;
}

@media (max-width: 768px) {
  .footer__middle {
    font-size: 12px;
  }
}

.footer__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 75px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #fff;
}

@media (max-width: 768px) {
  .footer__bottom {
    font-size: 12px;
  }
}

@media (max-width: 650px) {
  .footer__bottom {
    font-size: 16px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}

.footer__bottom a {
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer__bottom a:hover {
  color: #00bbaa;
  text-decoration: underline;
}

.footer__bottom a:active {
  color: #f3bc65;
}

@media (max-width: 650px) {
  .footer__resived {
    margin-right: 39%;
  }
}

.footer__policy {
  margin-left: -50%;
}

@media (max-width: 1024px) {
  .footer__policy {
    margin-left: -35%;
  }
}

@media (max-width: 650px) {
  .footer__policy {
    margin-left: 0%;
  }
}

@media (max-width: 650px) {
  .footer__dib {
    margin-left: 36%;
  }
}

@media (max-width: 480px) {
  .footer__dib {
    margin-left: 23%;
  }
}

@media (max-width: 414px) {
  .footer__dib {
    margin-left: 8%;
  }
}
