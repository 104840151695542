.secondHeader {
  padding-top: 53px;
  padding-bottom: 75px;
}

@media (max-width: 996px) {
  .secondHeader {
    padding-top: 180px;
  }
}

.secondHeader__link a {
  color: #3396cd;
  font-size: 16px;
}

.secondHeader__link span {
  color: #c4c4c4;
  font-size: 16px;
}
