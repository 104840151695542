.header__container {
  max-width: 1700px;
  margin: 0 auto;
  padding-top: 25px;
}

@media (max-width: 1600px) {
  .header__container {
    max-width: 1400px;
  }
}

@media (max-width: 1440px) {
  .header__container {
    max-width: 1240px;
  }
}

@media (max-width: 1280px) {
  .header__container {
    max-width: 1080px;
  }
}

@media (max-width: 1024px) {
  .header__container {
    max-width: 924px;
  }
}

@media (max-width: 996px) {
  .header__container {
    display: none;
  }
}

.header__navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 996px) {
  .header__navbar {
    display: none;
  }
}

.header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__logo_img img {
  max-width: 150px;
}

@media (max-width: 1024px) {
  .header__logo_img img {
    max-width: 100px;
  }
}

.header__logo_textService {
  font-size: 59.1437px;
  line-height: 100%;
  color: #bf986c;
}

@media (max-width: 1024px) {
  .header__logo_textService {
    font-size: 48px;
  }
}

.header__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -40px;
}

.header__menu_item {
  width: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  margin-left: 15px;
  padding: 4px 12px;
  border: 2px solid transparent;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1024px) {
  .header__menu_item {
    font-size: 14px;
  }
}

.header__menu_item:hover {
  border: 2px solid #00bbaa;
  border-radius: 8px;
  color: #00bbaa;
}

.header__menu_itemPages {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #00bbaa;
  margin-left: 15px;
  padding: 4px 12px;
  border: 2px solid transparent;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1024px) {
  .header__menu_itemPages {
    font-size: 14px;
  }
}

.header__menu_itemPages:hover {
  border: 2px solid #00bbaa;
  border-radius: 8px;
}

.header__menu_itemError {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #fff;
  margin-left: 15px;
  padding: 4px 12px;
  border: 2px solid transparent;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1024px) {
  .header__menu_itemError {
    font-size: 14px;
  }
}

.header__menu_itemError:hover {
  border: 2px solid #fff;
  border-radius: 8px;
}

.header-pages {
  background-color: #222;
  padding-bottom: 25px;
}

@media (max-width: 996px) {
  .header-pages {
    padding-bottom: 0px;
  }
}
