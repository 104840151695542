.swiper-slide img {
  display: block;
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: 2s;
  transition: 2s;
  position: relative;
}

.swiper-slide img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform-origin: center;
          transform-origin: center;
}

.swiper-slide {
  overflow-x: hidden;
  overflow-y: hidden;
}
