.residentialSection {
  margin-bottom: 75px;
}

.residentialSection__titleBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0px 0px 75px 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.residentialSection__title {
  width: 85%;
  font-size: 4vw;
  font-weight: 500;
  color: #000;
}

.residentialSection__subTitle {
  width: 15%;
  font-size: 1.25vw;
  color: #000;
}

.residentialSection__infoBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e4e4e4;
  margin-bottom: 75px;
}

.residentialSection__info {
  width: 100%;
}

.residentialSection__info-time {
  margin-bottom: 1.8vw;
  margin-left: 25px;
}

.residentialSection__info-title {
  font-size: 1.8vw;
  line-height: 1.875vw;
  color: #000;
  margin-top: 25px;
  margin-bottom: 1.8vw;
  margin-left: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.residentialSection__info-title-text {
  margin-left: 25px;
}

.residentialSection__info-time_title {
  font-style: normal;
  font-size: 1.458vw;
  line-height: 2vw;
  letter-spacing: .035em;
  color: #505050;
  margin-right: 25px;
}

.residentialSection__info-time_value {
  font-size: 1.8vw;
  line-height: 1.875vw;
  text-transform: uppercase;
  color: #000;
}

.residentialSection__info-subtitle {
  margin-bottom: 1.8vw;
  margin-left: 25px;
}

.residentialSection__info-subtitle_title {
  font-style: normal;
  font-size: 1.458vw;
  line-height: 2vw;
  letter-spacing: .035em;
  color: #505050;
}

.residentialSection__info-subtitle_text {
  font-size: 1.8vw;
  line-height: 1.875vw;
  color: #000;
}

.residentialSection__info-description {
  width: 100%;
  text-align: center;
  background-color: #00bbaa;
  padding: 1vw 1.5vw;
  font-size: 1.8vw;
  line-height: 2vw;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.residentialSection__info-description_text {
  font-size: 1vw;
}

.residentialSection__info-description_value {
  font-size: 1.5vw;
  font-weight: 600;
}

.residentialSection__info-bottomBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 25px 0px 25px 25px;
}

.residentialSection__info-address {
  width: 50%;
}

.residentialSection__info-address_title {
  font-size: 1vw;
  letter-spacing: .035em;
  color: #717171;
}

.residentialSection__info-address_text {
  font-size: 1.25vw;
  color: #000;
  max-width: 230px;
}

.residentialSection__info-button {
  width: 100%;
  text-align: center;
}

.residentialSection__info-button a {
  padding: 8px 12px;
  border: 2px solid #fff;
  border-radius: 8px;
  background-color: #ffffff;
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #000;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.residentialSection__info-button a:hover {
  color: #00bbaa;
  text-decoration: underline;
}

.residentialSection__info-button a:active {
  outline: none;
}

.residentialSection__slider {
  width: 50%;
}

.residentialSection__slider_item {
  --ratio: 800 / 1450;
  background-position: 0 0;
  background-repeat: no-repeat;
  min-height: calc(59vw * var(--ratio));
  background-size: 100% 100%;
}

.residentialSection__descriptionBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.residentialSection__descriptionBox_button {
  width: 15%;
  background-color: #222222;
  position: relative;
}

.residentialSection__descriptionBox_button button {
  position: absolute;
  bottom: 0;
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  background-color: #00bbaa;
  width: 100%;
  text-decoration: none;
}

.residentialSection__descriptionBox_button button:hover {
  background-color: #00bbab59;
  color: #00bbaa;
  text-decoration: none;
}

.residentialSection__descriptionBox_button button:active {
  outline: none;
}

.residentialSection__descriptionBox_button-text {
  margin-top: 8px;
  margin-left: 15px;
  margin-bottom: 80px;
  text-align: left;
}

.residentialSection__descriptionBox_button-icon {
  text-align: right;
}

.residentialSection__descriptionBox_description {
  width: 42%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0vw 2vw;
}

.residentialSection__descriptionBox_description-text {
  font-size: 1vw;
  letter-spacing: .035em;
  color: #000;
  margin-bottom: 10px;
}

.residentialSection__descriptionBox_img {
  width: 43%;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
}
