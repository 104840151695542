.navigationRight__up {
  text-align: end;
  position: -webkit-sticky;
  position: sticky;
  margin-top: -74px;
  margin-right: 45px;
  bottom: 30px;
  z-index: 100;
}

@media (max-width: 768px) {
  .navigationRight__up {
    display: none;
  }
}

.navigationRight__link {
  color: #00bbaa;
}

.navigationRight__link:hover {
  cursor: pointer;
  color: #f3bc65;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.navigationRight__link:active {
  color: #f3bc65;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}
